export default () => {
	// if (window.location.host.indexOf("localhost") !== -1) {
	// 	return "https://api-staging.whistleblowing-compliant.eu/api/v1";
	// }
	// if (window.location.host.indexOf("staging") !== -1) {
	// 	return "https://api-staging.whistleblowing-compliant.eu/api/v1";
	// }
	// return "https://api.whistleblowing-compliant.eu/api/v1";

	return `https://api-staging.whistleblowing-compliant.eu/api/v1`
};
