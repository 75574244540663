import React, { Component } from 'react'

import { Get } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companies: []
    }

    onChangeCompanyHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getCompanies = () => Get(
      `/companies`,
      this.getCompanysSuccess,
      this.getCompanysError,
      this.load
    )
    getCompanysSuccess = payload => this.setState({ companies: payload })
    getCompanysError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          companies={ this.state.companies }
          onLoadCompany={ this.state.loading }

          getCompanies={ this.getCompanies }
          onChangeCompanyHOC={ this.onChangeCompanyHOC }
        />
      )
    }
  }
  return WithHOC;
}

export default HOC