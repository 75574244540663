import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Modal,
  Row, Col, 
  Card, CardBody, 
  Button, UncontrolledTooltip 
} from 'reactstrap'
import { getItem } from 'utils/tokenStore'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TransComponent from 'components/Trans'
import SearchForm from 'components/searchForm'
import CaseTypeForm from './Form'
import ConfirmationModal from 'components/Modal/confirmation'
import BKPTable from 'components/Table'
import { convertObjToBase64 } from 'utils/convertbase64'

import CaseTypeHOC from './actions'

class CaseType extends Component {

  componentDidMount = () => {
    const { role_id } = this.props.data.ProfileReducer.profile

    if ( role_id === 4 || getItem( 'BPK_USER_TYPE' ) !== 'non-case' ) {
      return this.props.history.push( '/dashboard/case-mngmt' )
    }

    this.props.getTypes(convertObjToBase64({"page": 0}))
  }

  render = () => {
    let isAdmin = [ 1, 2 ].indexOf( this.props.data.ProfileReducer.profile.role_id ) > -1
    
    return (
      <>
        <PageTitle
          heading={ <TransComponent i18nKey="case_type.title.main"/> }
          subheading={ <TransComponent i18nKey="case_type.desc.main"/> }
          icon="pe-7s-file icon-gradient bg-happy-itmeo"
          buttons={ isAdmin ? [ 
            {
              color: 'primary',
              onClick: () => {
                this.props.onChangeTypesHOC( 'showCreateType', true )
              },
              className: 'mr-2',
              content: <TransComponent i18nKey="case_type.title.create"/>
            }
           ] : []} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <SearchForm
                per_page={10}
                searchParams={this.props.searchParams}
                onChangeHOCState={this.props.onChangeTypesHOC}
                getListAPI={val => this.props.getTypes(val)}
              />
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={ 10 }
                    data={ this.props.caseTypes }
                    columns={[
                      {
                        Header: <TransComponent i18nKey="case_type.fields.en_name"/>,
                        accessor: 'en_name'
                      },
                      {
                        Header: <TransComponent i18nKey="case_type.fields.de_name"/>,
                        accessor: 'de_name'
                      },
                      ... isAdmin ? [{
                        Header: <TransComponent i18nKey="general.action"/>,
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id={ `UpdateSecModule_${ row.original.id }` }
                                className="mr-2 btn-icon btn-icon-only"
                                color={ 'primary' }
                                onClick={() => {
                                  this.props.onChangeTypesHOC( 'selectedType', row.original )
                                  this.props.onChangeTypesHOC( 'showUpdateType', row.original )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target={ `UpdateSecModule_${ row.original.id }` } placement="top">
                                <TransComponent i18nKey="case_type.tooltip.update"/>
                              </UncontrolledTooltip>
                              <Button
                                id={ `DeleteSecModule_${ row.original.id }`}
                                className="mr-2 btn-icon btn-icon-only"
                                color={ 'danger' }
                                onClick={() => {
                                  this.props.onChangeTypesHOC( 'showConfirmDeleteModal', true )
                                  this.props.onChangeTypesHOC( 'selectedType', row.original )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target={ `DeleteSecModule_${ row.original.id }`} placement="top">
                                <TransComponent i18nKey="case_type.tooltip.delete"/>
                              </UncontrolledTooltip>
                            </>
                          )
                        }
                      }] : []
                    ]}
                    showPagination={ true }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal
          isOpen={ this.props.showCreateType }
          size={ 'xl' }>
          <CaseTypeForm 
            mode={ 'create' }
            onLoadTypes={ this.props.onLoadTypes }
            selectedType
            onClickSubmit={ this.props.createType }
            onClickClose={ () => this.props.onChangeTypesHOC( 'showCreateType', false )}
          />
        </Modal>
        <Modal
          isOpen={ this.props.showUpdateType }
          size={ 'xl' }>
          <CaseTypeForm 
            onLoadTypes={ this.props.onLoadTypes }
            mode={ 'update' }
            selectedType={ this.props.selectedType }
            onClickSubmit={ val => this.props.updateType( this.props.selectedType.id, val ) }
            onClickClose={ () => this.props.onChangeTypesHOC( 'showUpdateType', false )}
          />
        </Modal>
        <ConfirmationModal
          open={ this.props.showConfirmDeleteModal }
          loading={ this.props.onLoadTypes }
          titleKey={ 'case_type.title.delete' }
          messageKey={ 'case_type.desc.delete' }
          onClose={ () => this.props.onChangeTypesHOC( 'showConfirmDeleteModal', false )}
          onClickConfirm={ () => this.props.deleteType( this.props.selectedType.id )} />
        { this.props.onLoadTypes && <LoadingOverlay /> }
      </>
    )
  }
}

export default CaseTypeHOC( CaseType )