import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {
	Modal,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	UncontrolledTooltip,
} from "reactstrap";
import _ from "lodash";
import TransComponent from "components/Trans";
import PageTitle from "components/Title/PageTitle";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import EditCompany from "./Edit/index";
import CreateCompany from "./Create";
import ConfirmationModal from "components/Modal/confirmation";
import { getItem } from "utils/tokenStore";
import BKPTable from "components/Table";

import CompanysHOC from "./actions/index";
import CategoryHOC from "./actions/category";
import FormHOC from "./actions/form";
import SearchForm from 'components/searchForm'
import { convertObjToBase64 } from 'utils/convertbase64'

class Companys extends Component {
	componentDidMount = () => {
		const { role_id } = this.props.data.ProfileReducer.profile;

		if (role_id === 4 || getItem("BPK_USER_TYPE") !== "non-case") {
			return this.props.history.push("/dashboard/case-mngmt");
		}
    this.props.getCompanies(convertObjToBase64({"page": 0}))
		this.props.getForms();
	};

	render = () => {
		const { role_id } = this.props.data.ProfileReducer.profile;
		let isAdmin = [1, 2].indexOf(role_id) > -1;

		return (
			<>
				<PageTitle
					heading={<TransComponent i18nKey="company.title.main" />}
					subheading={<TransComponent i18nKey="company.desc.main" />}
					icon="pe-7s-culture icon-gradient bg-happy-itmeo"
					buttons={
						isAdmin
							? [
									{
										color: "primary",
										onClick: () => {
											this.props.onChangeCompanyHOC("showCreateModal", true);
										},
										content: <TransComponent i18nKey="company.title.create" />,
									},
							  ]
							: []
					}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<SearchForm
								per_page={10}
								searchParams={this.props.searchParams}
								onChangeHOCState={this.props.onChangeCompanyHOC}
								getListAPI={val => this.props.getCompanies(val)}
							/>
							<Card className="main-card mb-3">
								<CardBody>
									<BKPTable
										pageSize={10}
										data={this.props.companies}
										columns={[
											{
												Header: (
													<TransComponent i18nKey="company.fields.name" />
												),
												accessor: "name",
											},
											{
												Header: (
													<TransComponent i18nKey="company.fields.locale" />
												),
												accessor: "locale",
											},
											{
												Header: (
													<TransComponent i18nKey="company.fields.contact" />
												),
												accessor: "contact",
											},
											{
												Header: (
													<TransComponent i18nKey="company.fields.lawyer_contact" />
												),
												Cell: (row) => (
													<p className="mb-0">
														{row.original.lawyer_contact || "-"}
													</p>
												),
											},
											{
												Header: (
													<TransComponent i18nKey="company.fields.email" />
												),
												accessor: "email",
											},
											...(isAdmin || role_id === 4
												? [
														{
															Header: (
																<TransComponent i18nKey="general.action" />
															),
															accessor: "id",
															Cell: (row) => {
																return (
																	<>
																		<Button
																			id={`UpdateCompany_${row.original.id}`}
																			className="mr-2 btn-icon btn-icon-only"
																			color="primary"
																			onClick={() =>
																				this.props.getSelectedCompany(
																					row.original.id
																				)
																			}>
																			<i
																				className={`btn-icon-wrapper ${
																					role_id === 4
																						? "pe-7s-info"
																						: "pe-7s-pen"
																				}`}>
																				{" "}
																			</i>
																		</Button>
																		<UncontrolledTooltip
																			target={`UpdateCompany_${row.original.id}`}
																			placement="top">
																			<TransComponent i18nKey="company.tooltip.update" />
																		</UncontrolledTooltip>
																		{isAdmin && (
																			<>
																				<Button
																					id={`DeleteCompany_${row.original.id}`}
																					className="mr-2 btn-icon btn-icon-only"
																					color="danger"
																					onClick={() => {
																						this.props.onChangeCompanyHOC(
																							"selectedCompany",
																							row.original
																						);
																						this.props.onChangeCompanyHOC(
																							"showDeleteModal",
																							true
																						);
																					}}>
																					<i className="pe-7s-close btn-icon-wrapper">
																						{" "}
																					</i>
																				</Button>
																				<UncontrolledTooltip
																					target={`DeleteCompany_${row.original.id}`}
																					placement="top">
																					<TransComponent i18nKey="company.tooltip.delete" />
																				</UncontrolledTooltip>
																			</>
																		)}
																	</>
																);
															},
														},
												  ]
												: []),
										]}
										showPagination={true}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				<Modal isOpen={this.props.showCreateModal} size={"xl"}>
					<CreateCompany
						forms={this.props.forms}
						caseCategory={this.props.caseCategory}
						onLoadCompanies={this.props.onLoadCompanies}
						onClickSubmit={this.props.createCompany}
						onClose={() =>
							this.props.onChangeCompanyHOC("showCreateModal", false)
						}
					/>
				</Modal>
				<Modal isOpen={this.props.showUpdateModal} size={"xl"}>
					<EditCompany
						isAdmin={isAdmin}
						caseCategory={this.props.caseCategory}
						onLoadCompanies={this.props.onLoadCompanies}
						selectedCompany={this.props.selectedCompany}
						onClickSubmit={this.props.updateCompany}
						onClose={() =>
							this.props.onChangeCompanyHOC("showUpdateModal", false)
						}
					/>
				</Modal>
				<ConfirmationModal
					open={this.props.showDeleteModal}
					titleKey={"company.title.main"}
					loading={this.props.onLoadAdvisor}
					messageKey={"company.desc.delete"}
					onClose={() =>
						this.props.onChangeCompanyHOC("showDeleteModal", false)
					}
					onClickConfirm={() =>
						this.props.deleteCompany(this.props.selectedCompany.id)
					}
				/>
				{(this.props.onLoadCompanies ||
					this.props.onLoadCategory ||
					this.props.onLoadForm) && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(CompanysHOC, CategoryHOC, FormHOC)(Companys);
