import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Modal, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import EditUserForm from './Edit'
import BKPTable from 'components/Table'
import CreateUserForm from './Create'
import ConfirmationModal from 'components/Modal/confirmation'
import { getItem } from 'utils/tokenStore'
import { convertObjToBase64 } from 'utils/convertbase64'
import SearchForm from 'components/searchForm'

import UsersHOC from './actions/index'
import CompanyHOC from './actions/company'

class Users extends Component {

  componentDidMount = () => {
    const { role_id, id } = this.props.data.ProfileReducer.profile

    this.props.getCompanies()

    if ( role_id === 4 ) {
      this.props.getUsers( id )
    } else {
      this.props.getUsers(null, convertObjToBase64({"page": 0}))
    }

    getItem( 'BPK_USER_TYPE' ) !== 'non-case' &&
    this.props.history.push( '/dashboard/case-mngmt' )
  }

  render = () => {

    let isAdmin = [ 1, 2 ].indexOf( this.props.data.ProfileReducer.profile.role_id ) > -1
    const { role_id } = this.props.data.ProfileReducer.profile

    return (
      <>
        <PageTitle
          heading={ <TransComponent i18nKey={ `user.title.main`}/>}
          subheading={ <TransComponent i18nKey={ `user.desc.main`}/> }
          icon="pe-7s-user icon-gradient bg-happy-itmeo"
          buttons={ isAdmin ? [
            {
              color: 'primary',
              onClick: () => {
                this.props.onChangeUserHOC( 'showCreateModal', true )
              },
              content: <TransComponent i18nKey={ `user.title.create`}/>
            }
          ] : []}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <SearchForm
                per_page={10}
                searchParams={this.props.searchParams}
                onChangeHOCState={this.props.onChangeUserHOC}
                getListAPI={val => this.props.getUsers(null, val)}
              />
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={ 10 }
                    data={ this.props.users }
                    columns={[
                      {
                        Header: <TransComponent i18nKey={ `user.fields.name`}/>,
                        Cell: row => {
                          return (
                            <p>{ `${ row.original?.first_name??'' } ${ row.original?.last_name??''  }`}</p>
                          )
                        }
                      },
                      {
                        Header: <TransComponent i18nKey={ `user.fields.email`}/>,
                        accessor: 'email'
                      },
                      {
                        Header: <TransComponent i18nKey={ `user.fields.mobile_contact`}/>,
                        Cell: (row) => (
                          <p>{ row.original.mobile_contact || 'N/A' }</p>
                        )
                      },
                      {
                        Header: <TransComponent i18nKey={ `user.fields.role`}/>,
                        accessor: 'role_name'
                      },
                      ...( isAdmin || role_id === 4 ) ? [
                        {
                          Header: <TransComponent i18nKey={ `general.action`}/>,
                          Cell: (row) => {
                            return (
                              <>
                                <Button
                                  id={ `UpdateUser_${ row.original.id }`}
                                  className="mr-2 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={() => {
                                    this.props.onChangeUserHOC( 'showUpdateModal', true )
                                    this.props.onChangeUserHOC( 'selectedUser', row.original )
                                  }}>
                                  <i className="pe-7s-pen btn-icon-wrapper"> </i>
                                </Button>
                                <UncontrolledTooltip target={ `UpdateUser_${ row.original.id }`} placement="top">
                                  <TransComponent i18nKey={ 'user.tooltip.update' }/>
                                </UncontrolledTooltip>
                                {
                                  isAdmin && (
                                    <>
                                      <Button
                                        id={ `DeleteUser_${ row.original.id }` }
                                        className="mr-2 btn-icon btn-icon-only"
                                        color="danger"
                                        onClick={() => {
                                          this.props.onChangeUserHOC( 'showDeleteModal', true )
                                          this.props.onChangeUserHOC( 'selectedUser', row.original )
                                        }}>
                                        <i className="pe-7s-close btn-icon-wrapper"> </i>
                                      </Button>
                                      <UncontrolledTooltip target={ `DeleteUser_${ row.original.id }` } placement="top">
                                        <TransComponent i18nKey={ 'user.tooltip.delete' }/>
                                      </UncontrolledTooltip>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        }
                      ] : []
                    ]
                  }
                  showPagination={ true } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal
          isOpen={ this.props.showCreateModal }
          size={ 'xl' }
        >
          <CreateUserForm
            { ... this.props }
            onClickSubmit={ this.props.createUser }
            onClose={ () => this.props.onChangeUserHOC( 'showCreateModal', false )}
          />
        </Modal>
        <Modal
          isOpen={ this.props.showUpdateModal }
          size={ 'xl' }
        >
          <EditUserForm
            mode={ 'Edit' }
            { ... this.props }
            onClickSubmitUser={ val => this.props.updateUser( this.props.selectedUser.id, val )}
            onClickSubmitPW={ val => this.props.updatePW( this.props.selectedUser.id, val )}
            onClose={ () => this.props.onChangeUserHOC( 'showUpdateModal', false )}
          />
        </Modal>
        <ConfirmationModal
          open={ this.props.showDeleteModal }
          titleKey={ `user.title.delete` }
          loading={ this.props.onLoadAdvisor }
          messageKey={ `user.desc.delete` }
          onClose={ () => this.props.onChangeUserHOC( 'showDeleteModal', false )}
          onClickConfirm={ () => this.props.deleteUser( this.props.selectedUser.id )}/>
        {( this.props.onLoadUsers || 
          this.props.onLoadCompanies 
        ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  CompanyHOC,
  UsersHOC
)(Users)